const IconFace = ({ fill = '#ffffff', className, dataTestid = 'square-face' }) => (
  <div>
    <svg
      className={className}
      data-testid={dataTestid}
      width="52"
      height="52"
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={`${className}-path`}
        data-testid={`${dataTestid}-path`}
        d="M45.8974 0H6.10081C2.73804 0 0 2.73794 0 6.10235V45.8976C0 49.2621 2.73804 52 6.10081 52H45.8974C49.262 52 52 49.2621 52 45.8976V6.10235C52 2.73794 49.262 0 45.8974 0ZM48.5133 45.8959C48.5133 47.3373 47.3406 48.5117 45.8974 48.5117H6.10081C4.65937 48.5117 3.48668 47.339 3.48668 45.8959V6.10235C3.48668 4.66096 4.65937 3.48656 6.10081 3.48656H45.8974C47.3389 3.48656 48.5133 4.65922 48.5133 6.10235V45.8976V45.8959Z"
        fill={fill}
      />
      <path
        className={`${className}-path-2`}
        data-testid={`${dataTestid}-path-2`}
        d="M41.0879 20.1299H31.0798V23.4612H41.0879V28.1448H44.4192V15.4463H41.0879V20.1299Z"
        fill={fill}
      />
      <path
        className={`${className}-path-3`}
        data-testid={`${dataTestid}-path-3`}
        d="M34.4094 32.7758H31.0798C31.0798 35.5504 28.8234 37.8067 26.0487 37.8067C23.274 37.8067 21.0176 35.5504 21.0176 32.7758H17.6863C17.6863 37.3862 21.4382 41.138 26.0487 41.138C30.6592 41.138 34.4112 37.3879 34.4112 32.7775H37.7408V29.4463H34.4094V32.7775V32.7758Z"
        fill={fill}
      />
      <path
        className={`${className}-path-4`}
        data-testid={`${dataTestid}-path-4`}
        d="M21.0705 23.4369H24.4018C24.4018 18.8265 20.6499 15.0747 16.0394 15.0747C11.4289 15.0747 7.67871 18.8265 7.67871 23.4369H11.0101C11.0101 20.6623 13.2665 18.406 16.0412 18.406C18.8158 18.406 21.0722 20.6623 21.0722 23.4369H21.0705Z"
        fill={fill}
      />
    </svg>
  </div>
);

export default IconFace;
